// src/components/ToggleAboutView.tsx
import React, { useContext } from "react";
import { ListItem, ListItemText, Switch } from "@mui/material";
import { AboutUsContext } from "../context/AboutUsContext";

const ToggleAboutView: React.FC = () => {
  const { showAbout, toggleShowAbout } = useContext(AboutUsContext);

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    toggleShowAbout(event.target.checked);
  };

  return (
    <ListItem>
      <ListItemText primary="About Us Page View" />
      <Switch
        checked={showAbout}
        onChange={handleToggle}
        inputProps={{ "aria-label": "About Us Page View Toggle" }}
        sx={{
          color: "black",
          "& .MuiSwitch-switchBase.Mui-checked": {
            color: "black",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            },
          },
          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "black",
          },
        }}
      />
    </ListItem>
  );
};

export default ToggleAboutView;
