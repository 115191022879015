// src/components/Navbar.tsx
import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  Box,
} from "@mui/material";
import LoginModal from "./LoginModal";
import { AuthContext } from "../context/AuthContext";

const Navbar: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const { logout, token } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleLogout = () => {
    logout();
    navigate("/about");
  };

  const handleAdminClick = () => {
    if (token) {
      // If user is logged in, navigate to Dashboard
      navigate("/admin");
    } else {
      // If user is not logged in, open Login Modal
      handleOpen();
    }
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#FF8C42", // Pleasing orange color
          paddingX: "20px", // 20px padding on sides
          minHeight: 48, // Smaller height
        }}
      >
        <Container maxWidth="lg">
          <Toolbar disableGutters sx={{ minHeight: "48px !important" }}>
            {/* Website Name */}
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, fontWeight: "bold", color: "black" }}
            >
              Albert Popescu's Stats
            </Typography>

            {/* Nav Links */}
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button
                component={Link}
                to="/about"
                sx={{
                  color: "black",
                  "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" },
                  fontWeight: "medium",
                }}
              >
                About
              </Button>
              <Button
                onClick={handleAdminClick}
                sx={{
                  color: "black",
                  "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" },
                  fontWeight: "medium",
                }}
              >
                Admin
              </Button>
              {token && (
                <Button
                  onClick={handleLogout}
                  sx={{
                    color: "black",
                    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" },
                    fontWeight: "medium",
                  }}
                >
                  Logout
                </Button>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <LoginModal open={open} handleClose={handleClose} />
    </>
  );
};

export default Navbar;
