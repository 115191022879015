// src/App.tsx
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Box, Container } from "@mui/material";
import Navbar from "./components/Navbar";
import AboutPage from "./pages/AboutPage";
import PrivateRoute from "./components/PrivateRoute";
import AdminPage from "./pages/AdminPage";

const App: React.FC = () => {
  return (
    <>
      <Navbar />
      {/* Apply global background and margin styling */}
      <Box sx={{ backgroundColor: "#333", minHeight: "100vh", paddingY: 4 }}>
        <Container maxWidth="md">
          <Routes>
            <Route path="/about" element={<AboutPage />} />
            <Route
              path="/admin"
              element={
                <PrivateRoute>
                  <AdminPage />
                </PrivateRoute>
              }
            />
            {/* Default route */}
            <Route path="/" element={<Navigate to="/about" replace />} />
            {/* Catch-all route */}
            <Route path="*" element={<Navigate to="/about" replace />} />
          </Routes>
        </Container>
      </Box>
    </>
  );
};

export default App;
