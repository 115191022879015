// src/components/NetWorthCounter.tsx
import React, { useContext } from "react";
import { Typography, Box } from "@mui/material";
import { AboutUsContext } from "../context/AboutUsContext";

const NetWorthCounter: React.FC = () => {
  const { netWorth } = useContext(AboutUsContext);

  const formatCurrency = (amount: number, currency: string) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency,
    }).format(amount);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        padding: 3,
        borderRadius: 2,
        textAlign: "center",
        boxShadow: 3,
        width: "100%",
      }}
    >
      <Typography variant="h6" color="textSecondary" sx={{ marginBottom: 2 }}>
        Net Worth:
      </Typography>
      <Typography variant="h4" color="textPrimary" sx={{ fontWeight: "bold" }}>
        {formatCurrency(netWorth, "EUR")}
      </Typography>
    </Box>
  );
};

export default NetWorthCounter;
