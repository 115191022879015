// src/components/NetWorthProgressBar.tsx
import React, { useContext } from "react";
import { Box, Typography, LinearProgress } from "@mui/material";
import { AboutUsContext } from "../context/AboutUsContext";

const NetWorthProgressBar: React.FC = () => {
  const { netWorth } = useContext(AboutUsContext);
  const target = 1_000_000; // 1 million EUR

  // Calculate the percentage towards the target
  const percentage = Math.min((netWorth / target) * 100, 100); // Ensure it doesn't exceed 100%

  return (
    <Box
      sx={{
        marginTop: 4,
        padding: 3,
        backgroundColor: "#f5f5f5",
        borderRadius: 2,
        boxShadow: 3,
        width: "100%",
      }}
    >
      <Typography variant="h6" color="textSecondary" gutterBottom>
        Progress Towards 1 Million EUR
      </Typography>
      <LinearProgress
        variant="determinate"
        value={percentage}
        sx={{
          height: 20,
          borderRadius: 10,
          backgroundColor: "#e0e0e0",
          "& .MuiLinearProgress-bar": {
            borderRadius: 10,
            backgroundColor: "#76c7c0",
          },
        }}
      />
      <Typography variant="body1" color="textPrimary" sx={{ marginTop: 1 }}>
        {netWorth.toLocaleString("en-US", {
          style: "currency",
          currency: "EUR",
        })}{" "}
        of{" "}
        {target.toLocaleString("en-US", {
          style: "currency",
          currency: "EUR",
        })}
      </Typography>
    </Box>
  );
};

export default NetWorthProgressBar;
