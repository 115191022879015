// src/components/RestrictedMessage.tsx
import React from "react";
import { Typography, Box } from "@mui/material";

const RestrictedMessage: React.FC = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#333", // Dark background
        padding: 4,
        borderRadius: 2,
        textAlign: "center",
        boxShadow: 3,
        minHeight: "300px", // Ensures the box has sufficient height
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Typography variant="h5" color="white" sx={{ mb: 2 }}>
        Albert does not want you to see this page.
      </Typography>
      <Typography variant="body1" color="white">
        Please talk to him.
      </Typography>
    </Box>
  );
};

export default RestrictedMessage;
