// src/components/ResetNetWorthButton.tsx
import React, { useContext } from "react";
import { Button, ListItem } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { AboutUsContext } from "../context/AboutUsContext";

const ResetNetWorthButton: React.FC = () => {
  const { resetNetWorth } = useContext(AboutUsContext);

  const handleReset = () => {
    if (
      window.confirm("Are you sure you want to reset the net worth to 0 EUR?")
    ) {
      resetNetWorth();
    }
  };

  return (
    <ListItem>
      <Button
        variant="outlined"
        color="secondary"
        startIcon={<RestartAltIcon />}
        onClick={handleReset}
        fullWidth
      >
        Reset Net Worth
      </Button>
    </ListItem>
  );
};

export default ResetNetWorthButton;
