// src/components/SideMenu.tsx
import React, { useState, useContext } from "react";
import {
  List,
  Divider,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Snackbar,
  Alert,
  ListItem, // Ensure ListItem is imported
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ToggleAboutView from "./ToggleAboutView";
import ResetNetWorthButton from "./ResetNetWorthButton";
import { AboutUsContext } from "../context/AboutUsContext";

const SideMenu: React.FC = () => {
  const { addIncome } = useContext(AboutUsContext);
  const [open, setOpen] = useState<boolean>(false);
  const [amount, setAmount] = useState<string>(""); // string to handle empty input
  const [currency, setCurrency] = useState<string>("USD");

  // Snackbar State
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAmount("");
    setCurrency("USD");
  };

  const handleSubmit = () => {
    const parsedAmount = parseFloat(amount);
    console.log(
      `Attempting to add income: Amount=${parsedAmount}, Currency=${currency}`
    );

    if (!isNaN(parsedAmount) && parsedAmount > 0) {
      addIncome(parsedAmount, currency as "USD" | "EUR" | "RON");
      setSnackbarMessage("Income added successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      handleClose();
    } else {
      setSnackbarMessage("Please enter a valid amount.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Box
      sx={{
        width: 250,
        backgroundColor: "#f0f0f0", // Light grey background
        borderRadius: "0 8px 8px 0", // Rounded corners on the right side
        boxShadow: 3,
        paddingTop: 2,
        display: "flex",
        flexDirection: "column",
        height: "100%", // Ensure full height coverage
      }}
    >
      <Typography variant="h6" sx={{ textAlign: "center", marginBottom: 2 }}>
        Admin Panel
      </Typography>
      <Divider />
      <List>
        {/* Toggle About Us Page View */}
        <ToggleAboutView />

        {/* Add Income Button */}
        <ListItem>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleOpen}
            fullWidth
          >
            Add Income
          </Button>
        </ListItem>

        {/* Reset Net Worth Button */}
        <ResetNetWorthButton />
      </List>

      {/* Add Income Modal */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Income</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              marginTop: 1,
              minWidth: "300px",
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              label="Amount"
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              InputProps={{ inputProps: { min: 0 } }}
              required
            />
            <FormControl>
              <InputLabel id="currency-label">Currency</InputLabel>
              <Select
                labelId="currency-label"
                value={currency}
                label="Currency"
                onChange={(e) => setCurrency(e.target.value)}
              >
                <MenuItem value="RON">RON</MenuItem>
                <MenuItem value="USD">USD</MenuItem>
                <MenuItem value="EUR">EUR</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar Notification */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SideMenu;
