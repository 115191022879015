// src/context/AboutUsContext.tsx
import React, { createContext, useState, ReactNode, useEffect } from "react";
import { Snackbar, Alert } from "@mui/material";

interface AboutUsContextType {
  showAbout: boolean;
  toggleShowAbout: (value: boolean) => void;
  netWorth: number; // Only EUR
  addIncome: (amount: number, currency: "USD" | "EUR" | "RON") => void;
  resetNetWorth: () => void; // New Function
}

export const AboutUsContext = createContext<AboutUsContextType>({
  showAbout: true,
  toggleShowAbout: () => {},
  netWorth: 0,
  addIncome: () => {},
  resetNetWorth: () => {}, // Initialize as empty function
});

interface AboutUsProviderProps {
  children: ReactNode;
}

export const AboutUsProvider: React.FC<AboutUsProviderProps> = ({
  children,
}) => {
  const [showAbout, setShowAbout] = useState<boolean>(() => {
    const saved = localStorage.getItem("showAbout");
    return saved ? JSON.parse(saved) : true;
  });

  const [netWorth, setNetWorth] = useState<number>(() => {
    const saved = localStorage.getItem("netWorth");
    const parsed = saved ? parseFloat(saved) : 0;
    return !isNaN(parsed) ? parsed : 0;
  });

  const toggleShowAbout = (value: boolean) => {
    setShowAbout(value);
    localStorage.setItem("showAbout", JSON.stringify(value));
  };

  // Define conversion rates relative to EUR
  const conversionRates: { [key in "USD" | "EUR" | "RON"]: number } = {
    EUR: 1,
    USD: 0.9, // Example rate: 1 USD = 0.9 EUR
    RON: 0.2, // Example rate: 1 RON = 0.2 EUR
  };

  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({
    open: false,
    message: "",
    severity: "success",
  });

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const addIncome = (amount: number, currency: "USD" | "EUR" | "RON") => {
    console.log(`Adding Income: Amount=${amount}, Currency=${currency}`);
    const rate = conversionRates[currency];
    console.log(`Conversion Rate for ${currency}: ${rate}`);

    if (rate === undefined) {
      console.error(`Conversion rate for ${currency} is not defined.`);
      setSnackbar({
        open: true,
        message: `Conversion rate for ${currency} is not defined.`,
        severity: "error",
      });
      return;
    }

    const amountInEUR = amount * rate;
    console.log(`Amount in EUR: ${amountInEUR}`);

    if (isNaN(amountInEUR)) {
      console.error(`Invalid amount after conversion: ${amountInEUR}`);
      setSnackbar({
        open: true,
        message: `Invalid amount after conversion.`,
        severity: "error",
      });
      return;
    }

    setNetWorth((prev) => {
      const updatedNetWorth = prev + amountInEUR;
      console.log(
        `Previous Net Worth: ${prev} EUR, Updated Net Worth: ${updatedNetWorth} EUR`
      );
      if (isNaN(updatedNetWorth)) {
        console.error(
          `Updated net worth is NaN. Previous: ${prev}, Amount In EUR: ${amountInEUR}`
        );
        setSnackbar({
          open: true,
          message: `Failed to update net worth.`,
          severity: "error",
        });
        return prev; // Prevent setting NaN
      }
      localStorage.setItem("netWorth", updatedNetWorth.toString());
      setSnackbar({
        open: true,
        message: `Income added successfully!`,
        severity: "success",
      });
      return updatedNetWorth;
    });
  };

  const resetNetWorth = () => {
    setNetWorth(0);
    localStorage.setItem("netWorth", "0");
    console.log("Net worth has been reset to 0 EUR.");
    setSnackbar({
      open: true,
      message: `Net worth has been reset to 0 EUR.`,
      severity: "success",
    });
  };

  useEffect(() => {
    const savedShowAbout = localStorage.getItem("showAbout");
    if (savedShowAbout) {
      setShowAbout(JSON.parse(savedShowAbout));
    }

    const savedNetWorth = localStorage.getItem("netWorth");
    if (savedNetWorth) {
      const parsed = parseFloat(savedNetWorth);
      if (!isNaN(parsed)) {
        setNetWorth(parsed);
        console.log(`Loaded net worth from localStorage: ${parsed} EUR`);
      } else {
        console.warn(
          `Invalid net worth in localStorage: ${savedNetWorth}. Resetting to 0.`
        );
        setNetWorth(0);
        localStorage.setItem("netWorth", "0");
      }
    }
  }, []);

  return (
    <AboutUsContext.Provider
      value={{ showAbout, toggleShowAbout, netWorth, addIncome, resetNetWorth }}
    >
      {children}
      {/* Snackbar Notification */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </AboutUsContext.Provider>
  );
};
