// src/pages/AdminPage.tsx
import React from "react";
import SideMenu from "../components/SideMenu";
import { Box } from "@mui/material";

const AdminPage: React.FC = () => {
  return (
    <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
      {/* Side Menu */}
      <SideMenu />
      {/* No additional content as per requirement */}
    </Box>
  );
};

export default AdminPage;
