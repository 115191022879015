// src/components/AboutComponent.tsx
import React from "react";
import { Typography, Box } from "@mui/material";
import NetWorthCounter from "./NetWorthCounter";
import TimeUntilMillionaire from "./TimeUntilMillionare";
import NetWorthProgressBar from "./NetWorthProgressBar"; // Import the new component

const AboutComponent: React.FC = () => {
  // Define the target date for the countdown
  const targetDate = new Date("2026-10-31T00:00:00");

  return (
    <Box sx={{ marginBottom: 4, width: "100%" }}>
      {/* Descriptive Paragraph */}
      <Typography
        variant="body1"
        color="white" // White text
        sx={{ lineHeight: 1.6, marginBottom: 4 }}
      >
        Welcome to Albert's Dashboard! This platform provides insightful metrics
        and real-time data to help you track your financial growth and set
        meaningful goals. Whether you're aiming to increase your net worth or
        achieve financial milestones, our tools are designed to support your
        journey towards success.
      </Typography>

      {/* Counters Section */}
      <Box
        component="section"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: "100%",
        }}
      >
        {/* Net Worth Counter */}
        <NetWorthCounter />

        {/* Time Until Millionaire Counter */}
        <TimeUntilMillionaire targetDate={targetDate} />

        {/* Net Worth Progress Bar */}
        <NetWorthProgressBar />
      </Box>
    </Box>
  );
};

export default AboutComponent;
