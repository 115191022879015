// src/index.tsx
import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import ErrorBoundary from "./components/ErrorBundary";
import "./index.css";
import App from "./App";
import { AboutUsProvider } from "./context/AboutUsContext";

const theme = createTheme();

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <AboutUsProvider>
        <ThemeProvider theme={theme}>
          <Router>
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </Router>
        </ThemeProvider>
      </AboutUsProvider>
    </AuthProvider>
  </React.StrictMode>,

  document.getElementById("root")
);
