// src/pages/AboutPage.tsx
import React, { useContext } from "react";
import { Box } from "@mui/material";
import AboutComponent from "../components/AboutComponent";
import RestrictedMessage from "../components/RestrictedMessage";
import { AboutUsContext } from "../context/AboutUsContext";

const AboutPage: React.FC = () => {
  const { showAbout } = useContext(AboutUsContext);

  return (
    <Box
    // sx={{
    //   paddingLeft: 40,
    //   paddingRight: 40,
    // }}
    >
      {showAbout ? <AboutComponent /> : <RestrictedMessage />}
    </Box>
  );
};

export default AboutPage;
