// src/components/TimeUntilMillionaire.tsx
import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";

interface TimeUntilMillionaireProps {
  targetDate: Date;
}

interface TimeRemaining {
  years: number;
  weeks: number;
  days: number;
  hours: number;
}

const TimeUntilMillionaire: React.FC<TimeUntilMillionaireProps> = ({
  targetDate,
}) => {
  const calculateTimeRemaining = (): TimeRemaining => {
    const now = new Date().getTime();
    const distance = targetDate.getTime() - now;

    if (distance < 0) {
      return { years: 0, weeks: 0, days: 0, hours: 0 };
    }

    const totalHours = Math.floor(distance / (1000 * 60 * 60));
    const years = Math.floor(totalHours / (24 * 365));
    const remainingHoursAfterYears = totalHours % (24 * 365);
    const weeks = Math.floor(remainingHoursAfterYears / (24 * 7));
    const remainingHoursAfterWeeks = remainingHoursAfterYears % (24 * 7);
    const days = Math.floor(remainingHoursAfterWeeks / 24);
    const hours = remainingHoursAfterWeeks % 24;

    return { years, weeks, days, hours };
  };

  const [timeRemaining, setTimeRemaining] = useState<TimeRemaining>(
    calculateTimeRemaining()
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000 * 60 * 60); // Update every hour

    return () => clearInterval(timer);
  }, [targetDate]);

  return (
    <Box
      sx={{
        backgroundColor: "#f5f5f5",
        padding: 2,
        borderRadius: 2,
        textAlign: "center",
        boxShadow: 3,
        marginTop: 2,
      }}
    >
      <Typography variant="h6" color="textSecondary">
        Time Until Millionaire:
      </Typography>
      <Typography variant="h4" color="textPrimary" sx={{ fontWeight: "bold" }}>
        {timeRemaining.years} Years, {timeRemaining.weeks} Weeks,{" "}
        {timeRemaining.days} Days, {timeRemaining.hours} Hours
      </Typography>
    </Box>
  );
};

export default TimeUntilMillionaire;
